import React from 'react'
import styled from 'styled-components'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'
import { RichText } from 'prismic-reactjs'
import ClickableImg from '../clickable-img'
import CustomRichText from '../custom-rich-text'
import { breakpoints } from '../../helpers/media'

const Wrapper = styled.div(({ noPadding }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 10px;
  ${!noPadding ? `@media only screen and (min-width: ${breakpoints.md}px) {
    padding: 120px 40px 80px 40px;
  }` : ''}
`)

const ContactWrapper = styled.div`
  color: ${DEFAULT_GREY_TITLE}
  overflow: hidden;
  margin-top: 20px;
  & h6 {
    margin: 0px;
    text-align: left;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0px;
    color: ${DEFAULT_GREY_TITLE};
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    padding: 40px;
    margin-right: 40px;
    max-width: 650px;
  }
`

const InformationWrapper = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  & a {
    color: ${DEFAULT_COLOR};
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    padding: 0px;
    & h1 {
      font-size: 18px;
    }
  }
`

const Contact = styled.div`
  margin-bottom: 15px;
`

const ImgWrapper = styled.div`
  width: 32vw;
  min-width: 400px;
  max-width: 50vw;
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 75vw;
  }
`
const DefaultKommittee = ({ introduction, contacts, image, hideBodyPrimary }) => {
  return (
    <Wrapper noPadding={hideBodyPrimary}>
      {!hideBodyPrimary && image && (
        <ImgWrapper>
          <ClickableImg fluid={image} />
        </ImgWrapper>
      )}
      <ContactWrapper>
        {!hideBodyPrimary ? <InformationWrapper>
          <CustomRichText richText={introduction} />
        </InformationWrapper> : null}
        {contacts.map((contact, i) => (
          <Contact key={i}>
            <h6>{RichText.asText(contact.contact_title)}</h6>
            <p>{RichText.asText(contact.contact_content)}</p>
          </Contact>
        ))}
      </ContactWrapper>
    </Wrapper>
  )
}

export default DefaultKommittee
