import React from 'react'
import styled from 'styled-components'
import { DEFAULT_GREY_TITLE } from '../../helpers/colors'
import { RichText } from 'prismic-reactjs'
import { breakpoints } from '../../helpers/media'
import ClickableImg from '../clickable-img'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #e6dddbc4;
  padding-top: 40px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Title = styled.h1`
  width: 50%;
  color: ${DEFAULT_GREY_TITLE};
  word-spacing: 100vw;
  text-align: center;
  width: 400px;
  max-width: 100%;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 20px;
  }
`

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 20px;
  color: ${DEFAULT_GREY_TITLE};
`

const ImgWrapper = styled.div`
  width: 240px;
  margin-bottom: 5px;
`

const Detail = styled.p`
  margin-top: 5px;
  font-weight: 800;
`

const Members = ({ title, members }) => {
  const renderMember = member => (
    <MemberWrapper>
      <ImgWrapper>
        {member.member_imageSharp && (
          <ClickableImg
            fluid={member.member_imageSharp.childImageSharp.fluid}
          />
        )}
      </ImgWrapper>
      <Detail>{RichText.asText(member.member_full_name)}</Detail>
      <Detail>{RichText.asText(member.member_role)}</Detail>
      {member.member_contact_details && (
        <Detail>{RichText.asText(member.member_contact_details)}</Detail>
      )}
    </MemberWrapper>
  )

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>{title ? RichText.asText(title) : 'Medlemmar'}</Title>
        {renderMember(members[0])}
      </HeaderWrapper>
      {members.slice(1, members.length).map(member => renderMember(member))}
    </Wrapper>
  )
}

export default Members
