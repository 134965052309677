import React, { useContext } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { imageContext } from '../../context/image-provider'

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`
const ClickableImg = props => {
  const { setImage } = useContext(imageContext)
  return (
    props.fluid && (
      <ImgWrapper onClick={() => setImage(props.fluid)}>
        <Img {...props} />
      </ImgWrapper>
    )
  )
}

export default ClickableImg
