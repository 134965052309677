import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { graphql } from 'gatsby'
import KommitteeContent from '../../components/kommittee-content'
import SektionenHero from './sektionen-hero'

const Sektionen = ({ data, pageContext, location }) => {
  let imageUrl = pageContext.sektionen.node.logo_image.url
  if (pageContext.sektionen.node.body[0] && pageContext.sektionen.node.body[0].primary?.committee_image.url) {
    imageUrl = pageContext.sektionen.node.body[0].primary?.committee_image.url
  }
  return (
    <Layout>
      <SEO title='Sektionstyrelsen' />
      <SektionenHero url={imageUrl} title="Styrelsen" />
      <KommitteeContent hideBodyPrimary committee={pageContext.sektionen.node} />
    </Layout>
  )
}

export default Sektionen

export const query = graphql`
  {
    prismic {
      allCommittee_pages {
        edges {
          node {
            _meta {
              lang
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
          }
        }
      }
    }
  }
`
