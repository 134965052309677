import React from 'react'
import styled from 'styled-components'
import ImageTeaser from '../image-teaser'
import DefaultKommittee from './default-kommittee'
import Members from './members'
import GalleryScroller from '../gallery-scroller'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  height: auto;
`

const KommitteeContent = ({ committee, hideBodyPrimary }) => {
  const renderSlice = (slice, i) => {
    switch (slice.type) {
      case 'image_gallery':
        return (
          <GalleryScroller
            images={slice.fields.map(
              field => field.gallery_imageSharp.childImageSharp
            )}
          />
        )
      case 'default_committee_view':
        return (
          <DefaultKommittee
            key={i}
            hideBodyPrimary={hideBodyPrimary}
            introduction={slice.primary.introduction}
            contacts={slice.fields}
            image={
              slice.primary.committee_imageSharp &&
              slice.primary.committee_imageSharp.childImageSharp.fluid
            }
          />
        )
      case 'members':
        return (
          <Members
            key={i}
            title={slice.primary.members_title}
            members={slice.fields}
          />
        )
      default:
        return <h1 key={i}>SLICE TYPE: {slice.type} not found</h1>
    }
  }
  return committee ? (
    <div style={{ display: 'block' }}>
      {' '}
      {/*IDIOT FIX FOR SAFARI...stupid af */}
      <Wrapper>
        {committee.logo_imageSharp && (
          <ImageTeaser
            logo={committee.logo_imageSharp.childImageSharp.fluid}
            name={committee.name}
            information={committee.information}
          />
        )}
        {committee.body.map((slice, i) => renderSlice(slice, i))}
      </Wrapper>
    </div>
  ) : <h1>Not found</h1>
}

export default KommitteeContent
