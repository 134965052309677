import React, { useContext } from 'react'
import styled from 'styled-components'
import { imageContext } from '../../context/image-provider'
import { breakpoints } from '../../helpers/media'
import ClickableImg from '../../components/clickable-img'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`
const CarouselWrapper = styled.div(
  ({ viewWidth, visibleElements }) => `
  width: ${viewWidth * visibleElements + 5}vw;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  justify-content: flex-start;
  position: relative;
  margin: 10px;
`
)
const ImgInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
`
const ImgWrapper = styled.div(
  ({ viewWidth }) => `
  transition: transform 0.7s ease-in-out;
  min-height: ${viewWidth * 7.5}px;
  height: ${viewWidth/1.6}vw;
  min-width: ${viewWidth}vw;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 40vw;
    max-height: 150px;
    min-height: 150px;
  }
`
)
const GalleryScroller = ({ images, viewWidth = 30, visibleElements = 3 }) => {
  const { setImage } = useContext(imageContext)
  return (
    <Wrapper>
      <CarouselWrapper viewWidth={viewWidth} visibleElements={visibleElements}>
        {images.map((img, i) => (
          <ImgWrapper
            key={i}
            viewWidth={viewWidth}
          >
            <ImgInnerWrapper>
              <ClickableImg
                fluid={img.fluid}
                style={{ width: '100%', height: '100%' }}
              />
            </ImgInnerWrapper>
          </ImgWrapper>
        ))}
      </CarouselWrapper>
    </Wrapper>
  )
}

export default GalleryScroller
