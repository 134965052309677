import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'
import { breakpoints } from '../../helpers/media'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div`
  display: block;
  margin: 10px;
  height: auto;
  @media only screen and (min-width: ${breakpoints.md}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px;
    flex-wrap: wrap;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  max-width: 75vw;
  height: 375px;
  min-height: 375px;
`

const Divider = styled.div`
  margin: 20px;
  width: 2px;
  height: 300px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 65vw;
    height: 2px;
  }
`

const TextWrapper = styled.div`
  padding: 20px;
  & p {
    font-size: 16px;
    letter-spacing: 0px;
    color: #4c4d4fe5;
    opacity: 1;
  }
  & a {
    color: black;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    max-width: 35%;
  }
  min-width: 250px;
`

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 25px;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  opacity: 1;
`
const ImageTeaser = ({ logo, name, information }) => {
  return (
    <Wrapper>
      <ImgWrapper>
        <Img
          fluid={logo}
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </ImgWrapper>
      <Divider />
      <TextWrapper>
        <Title>{RichText.asText(name)}</Title>
        <CustomRichText richText={information}/>
      </TextWrapper>
    </Wrapper>
  )
}

export default ImageTeaser
